.App {
  position: absolute;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
                      "header"
                      "main"
                      "status";
}

header {
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.8rem;
  text-align: center;

  & h1 {
    margin-bottom: 0;
  }
}

main {
  grid-area: main;
  display: flex;
  justify-content: center;
  // align-items: center;
  padding-top: 5rem;
}

.form {
  display: flex;
  flex-direction: column;

  @media (max-width: 25em) {
    width: 80%;
  }
}

.form label {
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: 700;

  &.center {
    text-align: center;
    text-transform: uppercase;
  }
}

.form input {
  margin-bottom: 2rem;
  padding: 1.2rem;
  width: 30rem;
  height: 4rem;
  border: 0.05rem solid rgba( 0, 0, 0, 0.1);
  background-color: #dedede;
  border-radius: 0.7rem;
  outline: none;
  transition: border 0.3s ease;
  font-size: 1.6rem;

  &:focus {
    border-color: rgba( 0, 0, 0, 0.5);
  }

  @media (max-width: 25em) {
    width: 100%;
  }
}

.form select {
  width: 50%;
  margin: 0 auto 4rem;
  display: block;
  font-size: 1.6rem;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  outline: none;
  line-height: 1.3;
  padding: .5rem 1.2rem .5rem .6rem;
  box-sizing: border-box;
  border: 0.1rem solid #aaa;
  box-shadow: 0 0.1rem 0 0.1rem rgba(0, 0, 0, .04);
  border-radius: .5rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7rem top 50%, 0 0;
  background-size: .9rem auto, 100%;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #aaa;
    color: #222;
    outline: none;
  }

  & option {
    font-weight: normal;
  }
}

.form button {
  margin: 0 auto;
  border: 1px solid #4285F4;
  border-radius: 1rem;
  background-color: #4285F4;
  color: white;
  padding: 1.5rem 2rem;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.3s ease, transform 0.05s ease;
  outline: none;
  font-family: 'Josefin Sans', sans-serif;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.1rem;
  font-weight: bold;
  font-size: 1.5rem;

  &:hover {
    background-color: #f7f7f7;
    color: #4285F4;
  }

  &:active {
    transform: scale(0.95);
  }
}

.status-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-area: status;

  @media (max-width: 37.5em) {
    padding-top: 4rem;
  }

  @media (max-width: 25em) {
    padding-top: 2rem;
  }

}

.status {
  font-size: 1.7rem;
  padding: 0 1rem 1rem 1rem;
  overflow: hidden;

  @media (max-width: 25em) {
    margin-top: 2rem;
  }
}

.status a {
  color: #303030;
  font-weight: bold;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-color: #4285F4;
    width: 120%;
    height: 40%;
    transform: rotate(-3deg) translateX(-50%);
    left: 50%;
    bottom: 0;
    z-index: -1;
    transition: transform 0.2s ease;
  }

  &:hover::after {
    transform: rotate(-3deg) translateX(-150%);
  }
}